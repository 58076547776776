import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { Observable, filter, map, mergeMap, of } from 'rxjs';
import { AuthService } from 'src/lib/auth/auth.service';
import { IBooking } from 'src/lib/repository/booking/booking.model';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { PackagegoodsApi } from 'src/lib/repository/packagegoods/packagegoods.api';
import { IPackagegoods } from 'src/lib/repository/packagegoods/packagegoods.model';
import { BrandService } from 'src/lib/services/brand.service';
import { Utils } from 'src/lib/utils';
import {
  HotelMrhstSelectorComponent,
  IHotelMrhstSelectorData,
} from '../components/hotel-mrhst-selector/hotel-mrhst-selector.component';
import { DialogService } from './dialog.service';
import { PackagegoodsDetailService } from './packagegoods-detail.service';

// TODO: api 서비스는 BookingApi로 변경, 이 서비스는 BookingService로 변경
/**
 * Booking 클라이언트 데이터 처리 서비스
 */
@Injectable({
  providedIn: 'root',
})
export class BookingDataService {
  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private packagegoodsApiService: PackagegoodsApi,
    private packagegoodsDetailService: PackagegoodsDetailService,
    private brandService: BrandService,
  ) {}

  /**
   * 예약 취소 가능 여부
   */
  canCancelBooking(booking: IBooking): boolean {
    if (!booking) {
      return false;
    }

    const { bookingStateCode, startDate, returnDate, packagegoods } = booking;

    // 이미 취소됨
    if (bookingStateCode === 'CANCEL') {
      this.dialogService.alert('이미 취소된 예약입니다.').subscribe();
      return false;
    }

    // 완료됐거나 종료됨
    if (
      bookingStateCode === 'COMPLETE' &&
      new Date(returnDate!) <= new Date()
    ) {
      this.dialogService
        .alert('이미 종료된 행사는 변경 또는 취소 할 수 없습니다.')
        .subscribe();
      return false;
    }

    // 취소 기간 제한 있으면
    if (packagegoods && packagegoods.cancelDaysUntilStart) {
      // 시분초 제거한 오늘
      const todayDayjs = dayjs().endOf('day');
      // 취소 가능한 마지막 날
      const latestCancelableDayjs = dayjs(startDate).subtract(
        packagegoods.cancelDaysUntilStart,
        'day',
      );
      // 마지막 날 이후면 취소 불가
      const notCancelable = todayDayjs.isAfter(latestCancelableDayjs);

      if (notCancelable) {
        const message = this.translateService.instant(
          'packagegoods.cancelDaysUntilStart.invalid',
          { days: packagegoods.cancelDaysUntilStart },
        );
        this.dialogService.alert(message).subscribe();

        return false;
      }
    }

    return true;
  }

  /**
   * 패키지 유효성 검사
   */
  validatePackagegoods(
    packagegoods: IPackagegoods,
    tourNumber: number,
  ): Observable<boolean> {
    // 패키지 없음
    if (!packagegoods) {
      const message = this.translateService.instant('ALERT.Goods_Not_Found');
      this.dialogService.alert(message).subscribe();
      return of(false);
    }

    const { title, maxTourNumber, availHotelMrhstListJson } = packagegoods;
    const availHotelMrhstList = Utils.getParsedJson(
      availHotelMrhstListJson!,
      [],
    );

    // 이용 가능 숙박 시설 없음
    if (!availHotelMrhstList.length) {
      const message = this.translateService.instant('ALERT.Goods_Not_Found');
      this.dialogService.alert(message).subscribe();
      return of(false);
    }

    // 패키지 인원제한 검사, package-region-select-modal에서도 검사함
    // 인원수 제한을 초과
    if (maxTourNumber && maxTourNumber < tourNumber) {
      const message = this.translateService.instant(
        'packagegoods.maxTourNumber.invalid',
        {
          name: title,
          cnt: maxTourNumber,
        },
      );

      this.dialogService.alert(message).subscribe();
      return of(false);
    }

    // FIXME: 임시 하드코딩
    return this.authService.getAccount$().pipe(
      map((loginInfo) => {
        const { customerClass } = loginInfo?.userInfo || {};
        // EWRC 여부
        const isEwrc = packagegoods.brandId === 2;
        // 골드 또는 특별
        const isGoldOrSpecial =
          customerClass === '특별' || customerClass === '골드';

        // EWRC, 골드 또는 특별이며 2인 초과시
        if (isEwrc && isGoldOrSpecial && tourNumber > 2) {
          this.dialogService
            .alert('2인 초과 시 추가 회원권이 필요합니다.')
            .subscribe();

          return false;
        }

        return true;
      }),
    );
  }

  /**
   * 숙박 시설 선택 모달 열기
   */
  openHotelMrhstSelect(
    packagegoods: IPackagegoods,
    tourNumber: number,
    selected?: IMrhst,
    skipIfOnlyOne = true,
  ): Observable<[IMrhst, IMrhst[]]> {
    return this.packagegoodsApiService
      .getAvailMrhstList(packagegoods.id!, {
        goodsTypeList: ['HOTEL'],
      })
      .pipe(
        mergeMap(({ hotelMrhstList }) => {
          this.packagegoodsDetailService.hotelMrhstIdList = [];

          hotelMrhstList.forEach((mrhst) => {
            this.packagegoodsDetailService.mrhstMap.set(mrhst.id!, mrhst);
            this.packagegoodsDetailService.hotelMrhstIdList.push(mrhst.id!);
          });

          if (hotelMrhstList.length === 1 && skipIfOnlyOne) {
            return of([hotelMrhstList[0], hotelMrhstList] as [
              IMrhst,
              IMrhst[],
            ]);
          }

          return this.dialogService
            .open(
              HotelMrhstSelectorComponent,
              <IHotelMrhstSelectorData>{
                selected,
                mrhstList: hotelMrhstList,
                tourNumber,
              },
              {
                backdrop: 'static',
              },
            )
            .onDialogClosing.pipe(
              map((mrhst: IMrhst) => {
                return [mrhst, hotelMrhstList] as [IMrhst, IMrhst[]];
              }),
            );
        }),
        filter(([mrhst]) => !!mrhst),
        // tap(() => {
        //   if (this.brandService.brand.id == 2) {
        //     this.dialogService.alert('ALERT.beforeBookingNotice').subscribe();
        //   }
        // }),
      );
  }
}
