import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { MrhstApi } from 'src/lib/repository/mrhst/mrhst.api';
import { IMrhst } from 'src/lib/repository/mrhst/mrhst.model';
import { BrandService } from 'src/lib/services/brand.service';
import { HomeScreenService } from 'src/lib/services/home-screen.service';
import { Utils } from 'src/lib/utils';
import { DialogService } from '../../services/dialog.service';
import { PackagegoodsDetailService } from '../../services/packagegoods-detail.service';
import { DialogRef } from '../dialog-ref';
import { DialogAbstract } from '../dialog.abstract';

/**
 * 시설 선택 시 사용할 데이터
 */
export interface IHotelMrhstSelectorData {
  mrhstList: IMrhst[];
  selected?: IMrhst;
  tourNumber?: number;
}

@Component({
  selector: 'app-hotel-mrhst-selector',
  templateUrl: './hotel-mrhst-selector.component.html',
  styleUrls: ['./hotel-mrhst-selector.component.scss'],
})
export class HotelMrhstSelectorComponent
  extends DialogAbstract
  implements OnInit
{
  override dialogRef!: DialogRef<this, IMrhst, IHotelMrhstSelectorData>;

  /**
   * 이용 가능 호텔 목록
   */
  mrhstList: IMrhst[] = [];

  /**
   * 숙박 시설 아이디 폼컨트롤
   */
  mrhstIdFormControl = new FormControl();

  constructor(
    protected override elementRef: ElementRef<HTMLElement>,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private packagegoodsDetailService: PackagegoodsDetailService,
    private mrhstService: MrhstApi,
    private brandService: BrandService,
    private homeScreenService: HomeScreenService,
  ) {
    super(elementRef);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    if (this.dialogRef.data!.selected) {
      this.mrhstIdFormControl.setValue(this.dialogRef.data!.selected.id);
    }

    if (this.dialogRef.data!.mrhstList?.length === 1) {
      [this.dialogRef.data!.selected] = this.dialogRef.data!.mrhstList;
    }

    this.dialogRef.data!.mrhstList = this.dialogRef.data!.mrhstList.sort(
      (a, b) => (a.sellSort ?? 0) - (b.sellSort ?? 0),
    );

    this.reloadMrhst();
  }

  /**
   * 확인 버튼 클릭시
   */
  onOkButtonClick(): void {
    const selectedMrhst = this.dialogRef.data!.mrhstList.find((mrhst) => {
      return mrhst.id === this.mrhstIdFormControl.value;
    });

    // 선택 시설 못찾으면
    if (!selectedMrhst) {
      // 안내
      // TODO: 토스트로 구현
      this.dialogService.alert('ALERT.Input_Hotel_Mrhst');
      return;
    }

    if (this.brandService.brand.id == 2) {
      // this.dialogService
      //   .alert('ALERT.beforeBookingNotice')
      //   .pipe(
      //     tap(() => {
      //     }),
      //   )
      //   .subscribe();
      this.dialogRef.close(selectedMrhst);
    } else {
      this.dialogRef.close(selectedMrhst);
    }
  }

  /**
   * 숙박시설 둘러보기 버튼 클릭
   */
  onRoomDetailButtonClick({ id }: IMrhst): void {
    this.mrhstService
      .findItem(id!)
      .pipe(
        mergeMap((mrhst) => {
          return this.packagegoodsDetailService.openMrhstDescriptionDialog$(
            mrhst.id!,
            mrhst,
          );
        }),
      )
      .subscribe();
  }

  /**
   * 숙박 시설 비활성 여부
   */
  isDisabled(mrhst: IMrhst): boolean {
    // 이용 가능한 최소 인원
    if ((mrhst.mrhstAvailMinPerson ?? 0) <= this.dialogRef.data!.tourNumber!) {
      // 이용 가능한 최대 인원 넘으면 비활성
      if (
        mrhst.mrhstAvailMaxPerson != null &&
        this.dialogRef.data!.tourNumber! > mrhst.mrhstAvailMaxPerson
      ) {
        return true;
      }

      return false;
    }

    // 이용 가능한 최소 인원보다 적으면 비활성
    return true;
  }

  /**
   * imgUrlJson 첫번째 URL 반환
   */
  getFirstImgUrl(mrhst?: IMrhst): string {
    let imgUrlList: string[] = [];

    const fileDataPathList = mrhst!.imgFileDataList?.map((file) => file.path!);

    if (fileDataPathList?.length) {
      imgUrlList = fileDataPathList;
    } else {
      imgUrlList = Utils.getParsedJson(mrhst!.imgUrlListJson!, []);
    }

    return imgUrlList[0];
  }

  /**
   * 매장 정보 갱신
   */
  private reloadMrhst(): void {
    this.mrhstList = this.dialogRef.data!?.mrhstList ?? [];
    // const mrhstList$: Observable<IMrhst>[] = [];

    // this.data?.mrhstList?.forEach((mrhst) => {
    //   mrhstList$.push(this.packagegoodsDetailService.getMrhst$(mrhst.id!));
    // });

    // this.loadingService.start();

    // 앞에서 정렬한 순서대로 표시하기 위해 forkJoin
    // forkJoin(mrhstList$)
    //   .pipe(
    //     tap((mrhstList) => {
    //       this.mrhstList = mrhstList;
    //     }),
    //     finalize(() => {
    //       this.loadingService.stop();
    //     }),
    //   )
    //   .subscribe();
  }
}
