<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h1 class="modal-title fs-5">{{ '시설 선택' | translate }}</h1>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
    <div class="modal-body">
      <!--룸 목록 -->
      <ul class="room-list">
        <li class="room-item" *ngFor="let item of mrhstList">
          <label class="room-item-label">
            <input
              type="radio"
              name="room"
              [formControl]="mrhstIdFormControl"
              [value]="item.id"
              [disabled]="isDisabled(item)"
            />
            선택

            <!-- 룸 이미지 -->
            <div class="room-img-wrapper">
              <img
                loading="lazy"
                class="room-img"
                [src]="getFirstImgUrl(item)"
              />
            </div>

            <!-- Radio 버튼 및 룸 이름 -->
            <div class="room-info-wrapper">
              <span class="room-name">
                {{ item.mrhstNm }}
              </span>
            </div>
          </label>

          <!-- 룸 둘러보기 -->
          <button
            class="room-info-button"
            mat-flat-button
            (click)="onRoomDetailButtonClick(item)"
          >
            {{ '숙박시설 둘러보기' | translate }}
          </button>

          <ng-container *ngIf="item.mrhstNoteMessage as message">
            <h3 class="room-message-title">참고사항</h3>
            <span class="room-message-content">{{ message }}</span>
          </ng-container>
        </li>
      </ul>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" (click)="onOkButtonClick()">
        {{ 'BUTTON.Ok' | translate }}
      </button>

      <button class="btn btn-secondary" (click)="close(null)">
        {{ 'BUTTON.Close' | translate }}
      </button>
    </div>
  </div>
</div>
